<template lang="pug">
  .toasters
    PlanChannelToaster
    PriceChannelToaster
    FailedExportChannelToaster
    OtaAccountDisabled
</template>

<script>
  export default {
    components: {
      PlanChannelToaster: () => import("./PlanChannel/Toaster"),
      PriceChannelToaster: () => import("./PriceChannel/Toaster"),
      FailedExportChannelToaster: () => import("./FailedExportChannel/Toaster"),
      OtaAccountDisabled: () => import("./OtaAccountDisabled/Toaster")
    }
  }
</script>

<style lang="sass">
  .b-toaster
    z-index: 10000001 // AppOverlayLoader z-index + 1

    .b-toaster-slot
      overflow-y: auto
      max-height: 100vh
      padding: 7px 7px 15px 7px !important
      margin-right: 10px !important
</style>
